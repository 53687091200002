<template>
  <div>
    <v-data-table dense disable-pagination hide-default-footer :loading="loadingTable" :headers="headers"
      :items="products" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Produtos Lançados</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-menu :offset-y="true">
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn color="primary" outlined icon large v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                    <v-icon>
                      {{ icons.mdiTools }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ferramentas</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item @click="sendExportExcel()">
                <v-list-item-title>
                  <v-icon>{{ icons.mdiFileExcelOutline }}</v-icon> Exportar Excel
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="sendExportPdf()">
                <v-list-item-title>
                  <v-icon>{{ icons.mdiFilePdfOutline }}</v-icon> Exportar PDF
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="sendExportPdfPdv()">
                <v-list-item-title>
                  <v-icon>{{ icons.mdiFilePdfOutline }}</v-icon> Exportar PDF by PDV
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small outlined large class="ml-2" color="primary"
                @click="sendExportPdf()">
                <v-icon>{{ icons.mdiPrinter }}</v-icon>
              </v-btn>
            </template>
            <span>Imprimir</span>
          </v-tooltip>
          <v-badge :content="bagFilter" :value="bagFilter" bordered color="error" overlap>
            <v-btn @click="dialogFilter = true" class="ml-2" color="primary" outlined rounded>
              <v-icon>{{ icons.mdiFilter }}</v-icon>Filtros
            </v-btn>
          </v-badge>
          <v-spacer></v-spacer>
          <span>Periodo: de &nbsp <b style="color:black">{{ formatDate(filter.date_start) }}</b>&nbsp até &nbsp <b
              style="color:black">{{ formatDate(filter.date_end) }}</b></span>
        </v-toolbar>
      </template>
      <template v-slot:item.code="{ item }">
        {{ item.code }}
      </template>
      <template v-slot:item.status="{ item }">
        {{ status(item.status) }}
      </template>

      <template v-slot:item.sale_price="{ item }">
        {{ formatMoney(item.sale_price) }}
      </template>
      <template v-slot:item.pdv="{ item }">
        {{ item.pdv }}
      </template>

      <template v-slot:item.total="{ item }">
        {{ formatMoney(item.quantity * item.sale_price) }}
      </template>

      <template v-slot:item.actions="{ item }">
      </template>
      <template v-slot:foot>
        <tr style="height:44px; background:#fafafa">
          <td colspan="5" class="text-right" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">
            TOTAL:</td>
          <td class="text-right" style="border-top:solid 1px #e4e3e6 ;padding-left:16px;padding-right:16px">
            <b>{{ formatMoney(totalProduct()) }}</b>
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <NoDataTable></NoDataTable>
      </template>
      <template v-slot:loading>
        Carregando...
      </template>

    </v-data-table>

    <!-- DIALOG FILTER -->
    <v-dialog v-model="dialogFilter" width="700px">
      <v-card>
        <BarTitleDialog title="Filtros" @close="dialogFilter = false" />
        <v-card-text>
          <v-row>
            <v-col>
              <v-card outlined>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col>
                      <v-text-field v-model="filter.date_start" :error-messages="error.date_start"
                        @change="error.date_start = null" outlined label="Data inicial"
                        type="datetime-local"></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field class="ml-2" v-model="filter.date_end" :error-messages="error.date_end"
                        @change="error.date_end = null" outlined label="Data final"
                        type="datetime-local"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-select clearable hide-details="" v-model="filter.group_product_id" outlined
                        :items="getGroupProduct" item-value="id" label="Grupo de Produtos"></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="my-1">
                    <v-col>
                      <v-select clearable hide-details="" v-model="filter.pdv_id" :items='getPdvs'
                        item-text="description" outlined item-value="id" label="Ponto de Vendas"></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col class="text-left">
              <v-btn text color="error" x-large @click="clearFilter()">limpar filtros</v-btn>
            </v-col>

            <v-col class="text-right">
              <v-btn text color="secondary" x-large @click="dialogFilter = false">FECHAR</v-btn>
              <v-btn :loading="loadingFilter" :disabled="loadingFilter" @click="confirmFilter()" color="primary"
                class="ml-2" x-large>aplicar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <MsgDelete v-model="dialogDelete" @delete-yes="deleteItemConfirm()" />
  </div>
</template>

<script>
import MsgDelete from '@/components/MsgDelete.vue'
import NoDataTable from '@/components/NoDataTable.vue'
import { mdiClose, mdiTools, mdiFileExcelOutline, mdiFilter, mdiPrinter, mdiFilePdf, mdiFilePdfOutline } from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'
import BarTitleDialog from '@/components/BarTitleDialog.vue'
import SelectPdv from '@/components/SelectPdv.vue'

export default {
  components: {
    MsgDelete,
    NoDataTable,
    BarTitleDialog,
    SelectPdv,
  },
  data: () => ({
    // DIALOG FILTER
    dialogFilter: false,
    filter: {},
    itemsGroupProduct: [],
    loadingFilter: false,
    pdvs: [],

    categoryItems: [],
    loadingTable: false,
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    dialogDelete: false,
    dialog: false,
    loading: false,
    headers: [
      {
        text: 'CODIGO',
        align: 'start',
        value: 'code',
      },
      {
        text: 'DESCRIÇÃO',
        align: 'start',
        value: 'description',
      },
      {
        text: 'PONTO DE VENDA',
        align: 'start',
        value: 'pdv',
        sortable: false,
      },
      {
        text: 'QUANTIDADE',
        align: 'center',
        value: 'quantity',
        sortable: false,
      },
      {
        text: 'P. UNIT',
        align: 'end',
        value: 'sale_price',
        sortable: false,
      },
      {
        text: 'TOTAL',
        align: 'end',
        value: 'total',
        sortable: false,
      },
    ],
    products: [],
    editedIndex: -1,
    editedItem: {
      description: '',
    },
    defaultItem: {
      description: '',
    },
    options: {},
    icons: {
      mdiTools,
      mdiClose,
      mdiFileExcelOutline,
      mdiFilter,
      mdiPrinter,
      mdiFilePdf,
      mdiFilePdfOutline,
    },
    error: {
      date_start: null,
      date_end: null,
    },
  }),

  computed: {
    ...mapGetters('select', ['getGroupProduct', 'getPdvs']),
    bagFilter() {
      if (this.filter.group_product_id == null) {
        delete this.filter.group_product_id
      }

      return Object.keys(this.filter).length
    },
  },

  watch: {
    options: {
      handler() {
        this.initialize()
      },
    },
  },

  created() {
    //carregar sleect grupo de produto
    this.loadGroupProduct()
    this.loadPdvs()
    this.pdvs = this.getPdv


    //define datas de periodo inicial
    this.periodInitial()

    this.initialize()
  },

  methods: {
    ...mapActions('product_posted', ['getAll', 'exportExcel', 'exportPdf','exportPdfPdv']),
    ...mapActions('select', ['loadGroupProduct', 'loadPdvs']),

    initialize() {
      this.loadingTable = true

      //   const { page, itemsPerPage } = this.options

      //   const payload = {
      //     itemsPerPage: itemsPerPage || 10,
      //     page,
      //     search: this.search,
      //   }
      console.log(this.filter)
      this.getAll(this.filter)
        .then(response => {
          this.products = response.data

          this.dialogFilter = false
        })
        .finally(() => {
          this.loadingTable = false
          this.loadingFilter = false
          this.dialogFilter = false
        })
    },
    totalProduct() {
      return this.products.reduce((acc, product) => {
        return acc + parseFloat(product.sale_price) * parseFloat(product.quantity)
      }, 0)
    },
    periodInitial() {
      let dateToday = new Date();
      dateToday.setHours(dateToday.getHours() - 3);

      this.filter.date_end = dateToday.toISOString().slice(0, 10) + 'T' + '23:59'

      dateToday.setDate(dateToday.getDate() - 30);

      this.filter.date_start = dateToday.toISOString().slice(0, 10) + 'T' + '00:00'
    },
    confirmFilter() {
      //validar filtro periodo

      if (!this.filter.date_start) {
        this.error.date_start = 'Campo obrigatório'
        return false
      }
      if (!this.filter.date_end) {
        this.error.date_end = 'Campo obrigatório'
        return false
      }
      this.loadingFilter = true
      this.initialize()
    },
    sendExportPdf() {
      this.exportPdf(this.filter)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'export.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .finally()
    },
    sendExportPdfPdv() {
      this.exportPdfPdv(this.filter)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'export.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .finally()
    },
    clearFilter() {
      this.filter = {}
    },
    formatMoney(value) {
      if (value != null) {
        return value.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }
    },
    formatDate(dateTime) {
      if (!dateTime) return null

      const [date, time] = dateTime.split('T')
      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year} ` + time
    },
    sendExportExcel() {
      this.exportExcel(this.filter)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'export.xlsx')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(response => {

        })
        .finally(() => { })
    },
    listPdvs(pdvs) {
      const filteredList = Object.entries(pdvs)
        .filter(([key, value]) => value.quantity > 0)
        .map(([key, value]) => ({ description: value.description, quantity: value.quantity }));
      return filteredList;
    },
  },
}
</script>
